<template lang="pug">
    BTooltip(
      :target="target"
      triggers="hover"
      placement="auto"
    )
      FaIcon.icon(icon="exclamation-triangle")
      .tooltip-title
        .title {{ $t("plan_management.car_class_group_tooltip_title", { car_class_group_name: carClassGroup.group_name }) }}
      .tooltip-row(v-for="carClassName in carClassNames")
        .title {{ carClassName }}
</template>

<script>
  // components
  import { BTooltip } from "bootstrap-vue"

  export default {
    components: {
      BTooltip
    },

    props: {
      target: {
        type: String,
        required: true
      },
      carClassGroup: {
        type: Object,
        required: true
      }
    },

    computed: {
      carClassNames() {
        return this.carClassGroup.sources_car_class_names
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  ::v-deep
    .arrow
      display: none
    .tooltip-inner
      background-color: $default-black
      max-width: 300px

      .icon
        font-size: 1em
        color: $default-yellow
        margin: 10px 0

      .tooltip-title
        margin-bottom: 10px

      .tooltip-title,
      .tooltip-row
        align-items: center
        display: flex
        justify-content: space-between
        font-size: 0.75em
        width: 250px

        .title
          width: 100%
          text-align: center
</style>
