<template lang="pug">
  .sale-settings-container
    p.plan-name
      | {{ $t("plan_management.modal.plan_name") }}
      span {{ plan.name }}
    .contents
      .calendar-section
        Calendar(
          :period="calendarPeriod"
          :special-periods="specialPricePeriodsSorted"
          :selected-period-id="selectedPricePeriod.id"
          @select-special-period="selectSpecialPeriod"
          @add-special-period="addSpecialPeriod"
        )
      .settings-section
        .setting-row
          p.setting-title {{ $t("plan_management.modal.jalan_sale_settings.period_name") }}
          AppInput.period-name(
            type="text"
            v-model="selectedPricePeriod.name"
            :disabled="isSettingsFormDisabled"
          )
          .hint
            span {{ $t("plan_management.modal.jalan_sale_settings.period_name_hint") }}
        .setting-row
          p.setting-title
            FormFieldLabel(
              :title="$t('plan_management.modal.jalan_sale_settings.period')"
              required
            )
          AppDatepicker.period(
            :value="[selectedPricePeriod.start_date, selectedPricePeriod.end_date]"
            range
            @change="applyPeriod"
            :disabled-date="disabledDate"
            :disabled="isSettingsFormDisabled"
          )
        .setting-row
          p.setting-title {{ $t("plan_management.modal.jalan_sale_settings.classification") }}
          .classification-radio
            AppRadioButton(
              :value="selectedPricePeriod.period_type === 'special'"
              @change="enableSpecialPrice"
              :disabled="isSettingsFormDisabled"
            )
            .label
              span {{ $t("plan_management.modal.jalan_sale_settings.special_price") }}
          .classification-radio
            AppRadioButton(
              :value="selectedPricePeriod.period_type === 'sale_suspension'"
              @change="enableSaleSuspension"
              :disabled="isSettingsFormDisabled"
            )
            .label
              span {{ $t("plan_management.modal.jalan_sale_settings.suspension_price") }}
        .setting-row(v-if="!isSettingsFormDisabled && selectedPricePeriod.period_type === 'special'")
          p.setting-title
            FormFieldLabel(
              :title="$t('plan_management.modal.jalan_sale_settings.price')"
              required
            )
          .price-list
            .price-item(v-for="(value, fee) in selectedPricePeriod.fees")
              .label
                span {{ $t(`plan_management.time_slot.jalan.${feeType}.${fee}`) }}
              AppInput.price(
                type="number"
                :min="0"
                :value="value"
                @input="updateFee(fee, $event)"
              )
              .yen {{ $t("general_settings.yen") }}
        .setting-row.no-border(v-if="!isSettingsFormDisabled && selectedPricePeriod.period_type === 'special'")
          p.setting-title {{ $t("plan_management.modal.jalan_sale_settings.color_setting") }}
          .color-selection
            template(v-for="colorKey in specialPeriodColorKeys")
              RadioButton(
                :value="selectedPricePeriod.color === colorKey"
                :color="laneColors[colorKey]"
                @change="setLaneColor(colorKey)"
              )
        .setting-row
          .actions
            AppButton.delete(
              :disabled="isEmpty(selectedPricePeriod)"
              title="plan_management.modal.jalan_sale_settings.delete"
              @click="handleDeletePeriod"
            )
            AppButton.save(
              title="plan_management.modal.jalan_sale_settings.save_settings"
              @click="handleSaveChanges"
            )
</template>

<script>
  // misc
  import { isEmpty, filter, keys, find, findLast, map } from "lodash-es"
  import {
    LANE_COLORS,
    SALE_SUSPENSION_COLOR_KEY,
    SPECIAL_PERIOD_DEFAULT_COLOR_KEY,
    defaultSpecialPeriodObject
  } from "./../../../constants.js"
  import { startOfDay, endOfDay } from "date-fns"

  export default {
    components: {
      Calendar: () => import("./Calendar"),
      RadioButton: () => import("./RadioButton"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppInput: () => import("@/components/elements/AppInput"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    data() {
      return {
        pricePeriods: [],
        laneColors: LANE_COLORS,
        saleSuspensionColorKey: SALE_SUSPENSION_COLOR_KEY,
        specialPeriodDefaultColorKey: SPECIAL_PERIOD_DEFAULT_COLOR_KEY,
        selectedPricePeriod: {},
        selectedPricePeriodMaxRange: []
      }
    },

    props: {
      plan: {
        type: Object,
        required: true
      }
    },

    created() {
      this.setPricePeriods()
    },

    computed: {
      feeType() {
        return this.plan.plan_price_items[0].fee_type
      },

      specialPeriodColorKeys() {
        return filter(keys(this.laneColors), key => key > 0)
      },

      isSettingsFormDisabled({ selectedPricePeriod }) {
        return isEmpty(selectedPricePeriod)
      },

      specialPricePeriodsSorted({ pricePeriods }) {
        return filter(pricePeriods, ({ period_type }) => period_type !== "base").sort(
          (p1, p2) => p1.start_date - p2.end_date
        )
      },

      planStartDate() {
        return startOfDay(new Date(this.plan.sale_start_date))
      },

      planEndDate() {
        return startOfDay(new Date(this.plan.sale_end_date))
      },

      calendarPeriod({ planStartDate, planEndDate }) {
        return [planStartDate, planEndDate]
      },

      selectedPeriodMinDate({ isSettingsFormDisabled, selectedPricePeriod }) {
        if (isSettingsFormDisabled) {
          return this.planStartDate
        }

        const leftPeriod = findLast(this.specialPricePeriodsSorted, ({ id, end_date }) => {
          return id !== selectedPricePeriod.id && end_date < selectedPricePeriod.start_date
        })

        if (leftPeriod) {
          const periodMinDate = new Date(leftPeriod.end_date)
          return periodMinDate.setDate(periodMinDate.getDate() + 1)
        }
        return this.planStartDate
      },

      selectedPeriodMaxDate({ isSettingsFormDisabled, selectedPricePeriod }) {
        if (isSettingsFormDisabled) {
          return this.planEndDate
        }

        const rightPeriod = find(this.specialPricePeriodsSorted, ({ id, start_date }) => {
          return id !== selectedPricePeriod.id && start_date > selectedPricePeriod.end_date
        })

        if (rightPeriod) {
          const periodMaxDate = new Date(rightPeriod.start_date)
          return periodMaxDate.setDate(periodMaxDate.getDate() - 1)
        }

        return this.planEndDate
      }
    },

    methods: {
      isEmpty,
      startOfDay,
      endOfDay,

      setPricePeriods() {
        const pricePeriods = this.plan.plan_price_items[0]?.price_periods
        if (!isEmpty(pricePeriods)) {
          this.pricePeriods = map(pricePeriods, period => ({
            ...period,
            laneColor: this.laneColors[period.color],
            start_date: this.startOfDay(new Date(period.start_date)),
            end_date: this.endOfDay(new Date(period.end_date))
          }))
        }
      },

      disabledDate(date) {
        return date < this.selectedPeriodMinDate || date > this.selectedPeriodMaxDate
      },

      updateFee(feeKey, value) {
        this.selectedPricePeriod.fees[feeKey] = value
      },

      addSpecialPeriod(date) {
        const addedPeriod = defaultSpecialPeriodObject(date)
        this.pricePeriods.push(addedPeriod)
        this.selectedPricePeriod = addedPeriod
      },

      selectSpecialPeriod({ id }) {
        this.selectedPricePeriod = find(this.specialPricePeriodsSorted, period => period.id === id)
      },

      setLaneColor(colorKey) {
        this.selectedPricePeriod.color = colorKey
        this.selectedPricePeriod.laneColor = this.laneColors[colorKey]
      },

      applyPeriod(dateRange) {
        this.selectedPricePeriod.start_date = dateRange[0]
        this.selectedPricePeriod.end_date = dateRange[1]
      },

      enableSpecialPrice() {
        this.selectedPricePeriod.color = this.specialPeriodDefaultColorKey
        this.selectedPricePeriod.laneColor = this.laneColors[this.specialPeriodDefaultColorKey]
        this.selectedPricePeriod.period_type = "special"
      },

      enableSaleSuspension() {
        this.selectedPricePeriod.color = this.saleSuspensionColorKey
        this.selectedPricePeriod.laneColor = this.laneColors[this.saleSuspensionColorKey]
        this.selectedPricePeriod.period_type = "sale_suspension"
      },

      handleDeletePeriod() {
        const index = this.pricePeriods.indexOf(this.selectedPricePeriod)
        if (index) {
          this.selectedPricePeriod = {}
          this.pricePeriods.splice(index, 1)
        }
      },

      handleSaveChanges() {
        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .sale-settings-container
    width: 1185px

    .plan-name
      color: $default-gray
      font-size: 1.2rem

      span
        font-size: 1.0rem
    .contents
      display: flex
      justify-content: space-between

      .calendar-section
        width: 700px

      .settings-section
        width: 440px
        margin-right: 10px
        box-shadow: 2px 2px 5px transparentize($default-gray, 0.7)

        .setting-row
          margin: 15px 30px
          padding-bottom: 10px
          border-bottom: 1px solid $default-gray-light
          opacity: 0.8

          .actions
            display: flex
            justify-content: space-around

            button
              height: 30px
              width: 180px

            .delete
              border-color: $default-red
              color: $default-red

            .save
              color: $default-white
              background-color: $default-purple

          &.no-border
            border-bottom: none

          .color-selection
            display: flex

          .price-list
            .price-item
              display: flex
              justify-content: left
              margin-bottom: 3px

              .yen
                font-size: 0.8rem
                margin-left: 5px
                margin-top: 6px

              .label
                width: 30%
                font-size: 0.9rem
                margin-right: 10px
                padding: 5px 0px

              ::v-deep
                input
                  width: 100px

          .classification-radio
            display: flex
            justify-content: left

            .app-radio-button
              margin-right: 5px
              margin-top: 3px

              ::v-deep
                .big-circle
                  border: 1px solid $default-black
                  width: 15px
                  height: 15px

                .small-circle
                  width: 8px
                  height: 8px

            .label
              font-size: 0.9rem

          .period
            width: 100%
            padding-right: 0

          .hint
            text-align: right
            color: #786d44
            font-size: 0.8rem
            font-style: normal
            font-weight: 400

          ::v-deep
            input
              width: 100%
              height: 32px
              border-color: $default-purple

          .setting-title
            color: $default-gray
            font-weight: 600
            font-size: 0.85rem
            height: 20px
            margin-bottom: 5px
</style>
