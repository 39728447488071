<template lang="pug">
  tr.plan-row
    td.sticky-col.status
      AppToggle(
        :value="plan.selling_status"
        @change=""
      )
    td.sticky-col.ota-name
      span {{ translatedOtaNamesMapping[plan.ota_id] }}
    td.sticky-col.plan-name
      textarea.editable-field.form-control(
        type="text"
        :value="plan.name"
        @input=""
      )
    td.insurance
      span {{ this.$t(`plan_management.insurance.${plan.insurance}`) }}
    td.sale-start-date
      AppDatepicker.date(:value="new Date(plan.sale_start_date)")
    td.sale-stop-date
      AppDatepicker.date(:value="new Date(plan.sale_end_date)")
    td.posting-start-date
      AppDatepicker.date(
        v-if="plan.ota_id === jalanOtaId"
        :value="new Date(plan.display_from)"
      )
    td.posting-end-date
      AppDatepicker.date(
        v-if="plan.ota_id === jalanOtaId"
        :value="new Date(plan.display_to)"
      )
    td.car-class(:colspan="3")
      template(v-if="plan.ota_id === rakutenOtaId")
        template(v-for="plan_price_item in plan.plan_price_items")
          table.car-class-table
            tr.no-border(:class="{ 'added': plan_price_item._addedAt }")
              td.car-classes-row(:rowspan="plan_price_item.price_items.length + 2")
                .car-class(v-for="car_class in plan_price_item.car_classes")
                  span {{ car_class.name }}
                .warning-icon(:id="`car-class-group-${plan.id}-${plan_price_item.group_id}`")
                  AppIcon(name="exclamationTriangleRegular")
              CarClassGroupTooltip(
                :target="`car-class-group-${plan.id}-${plan_price_item.group_id}`"
                :car-class-group="plan_price_item"
              )
            template(v-for="(price_item, index) in plan_price_item.price_items")
              tr(:class="{ 'added': price_item._addedAt }")
                td.base-rate-period-group
                  span {{ price_item.period_name }}
                td.prices
                  PricesRow(
                    :fee-type="price_item.fee_type"
                    :fees="price_item.fees"
                    :ota-name="plan.ota_name"
                    @remove-row="removePriceItem(plan_price_item, index)"
                  )
                  AppButton.add-button.jalan-sale-settings(
                    v-if="plan.ota_name === jalanOtaId"
                    title="plan_management.actions.jalan_sale_settings",
                    @click="handleJalanSaleSettings(plan)"
                  )
            tr(
              v-if="plan.ota_id === rakutenOtaId"
              :class="{ 'added': plan_price_item._addedAt }"
            )
              td
                AppIconButton.add-button(
                  icon="plus-circle"
                  title="plan_management.actions.add_base_rate_period_group",
                  @click="handleAddBaseRatePeriodGroup(plan_price_item)"
                )
              td
        tr.no-border
          td
            AppIconButton.add-button(
              icon="plus-circle"
              title="plan_management.actions.add_car_class",
              @click="handleAddCarClass(plan)"
            )
          td
      template(v-else)
        template(v-for="plan_price_item in plan.plan_price_items")
          table.car-class-table
            tr.no-border
              td.car-classes-row(:rowspan="2")
                .car-class
                  span {{ plan_price_item.car_class_name }}
            template(v-for="price_period in plan_price_item.price_periods")
              tr.no-border(v-if="price_period.period_type === 'base'")
                td.base-rate-period-group
                td.prices
                  PricesRow(
                    :fee-type="plan_price_item.fee_type"
                    :fees="price_period.fees"
                    :ota-name="plan.ota_name"
                  )
                  AppButton.add-button.jalan-sale-settings(
                    title="plan_management.actions.jalan_sale_settings",
                    @click="handleJalanSaleSettings(plan)"
                  )
</template>

<script>
  // misc
  import { mapGetters } from "vuex"

  // components
  import JalanSaleSettingsModal from "./JalanSaleSettingsModal"
  import CarClassGroupTooltip from "./CarClassGroupTooltip"

  // mixins
  import withModal from "@/mixins/withModal"

  import { PRICE_MANAGEMENT_ALLOWED_OTA_IDS } from "@/config/constants"

  export default {
    components: {
      CarClassGroupTooltip,
      PricesRow: () => import("./PricesRow"),
      AppIcon: () => import("@/components/elements/AppIcon"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker")
    },

    mixins: [withModal],

    props: {
      plan: {
        type: Object,
        required: false
      }
    },

    computed: {
      ...mapGetters(["translatedOtaNamesMapping"]),

      jalanOtaId() {
        return PRICE_MANAGEMENT_ALLOWED_OTA_IDS.jalanId
      },

      rakutenOtaId() {
        return PRICE_MANAGEMENT_ALLOWED_OTA_IDS.rakutenId
      }
    },

    methods: {
      removePriceItem(carClass, priceItemIndex) {
        carClass.price_items.splice(priceItemIndex, 1)
      },

      handleJalanSaleSettings(plan) {
        this.$openModal({
          component: JalanSaleSettingsModal,
          title: this.$t("plan_management.actions.jalan_sale_settings"),
          closeOnClick: true,
          size: "large",
          props: {
            plan
          }
        })
      },

      handleAddCarClass(plan) {
        this.$openModal({
          component: () => import("./AddCarClassModal"),
          title: this.$t("plan_management.actions.add_car_class"),
          closeOnClick: true,
          props: {
            plan
          }
        })
      },

      handleAddBaseRatePeriodGroup(planPriceItem) {
        this.$openModal({
          component: () => import("./AddBaseRatePeriodGroupModal"),
          title: this.$t("plan_management.actions.add_base_rate_period_group"),
          closeOnClick: true,
          props: {
            planPriceItem
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/matchings.sass"

  .sticky-col
    +sticky-col
    background-color: $default-white
  tr
    border-bottom: 1px solid $default-gray-light

    &.no-border
      border-bottom: none

    .add-button
      background-color: $default-purple

      &.jalan-sale-settings
        margin: 10px 5px
        padding: 5px 10px

      ::v-deep
        svg
          path
            fill: $default-white
        .title
          color: $default-white
  td
    font-size: 0.8rem
    padding: 10px 10px
    vertical-align: top

    .date
      width: auto

      ::v-deep
        input.mx-input
          height: 30px
          border-color: $default-purple

    &.status
      left: 0
      z-index: 10
    &.ota-name
      left: 100px
      z-index: 10
    &.plan-name
      left: 200px
      z-index: 10
      ::v-deep
        textarea
          font-size: 0.9rem
          border-color: $default-purple
          min-height: 200px
          vertical-align: top

  .price-row
    display: flex
    max-width: 1200px
    min-width: 1200px

  .car-class-table
    width: 1000px

    tr
      &.added
        background-color: $default-purple-light
        border-color: $default-purple-light
      td
        text-align: left

        &.car-classes-row
          min-width: 120px
          max-width: 120px

          .warning-icon
            display: flex
            justify-content: center

        &.base-rate-period-group
          min-width: 240px
          max-width: 240px

        &.prices
          padding-top: 0

          svg
            cursor: pointer
</style>
